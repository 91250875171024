import React, { useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import axios from 'axios';
import { postUser } from '../Slices/User.slice';
import createHero from '../Assets/Blog/OBJECTS.svg';
import createLogo from '../Assets/Blog/Layer 2.svg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import userIcon from '../Assets/Blog/account_circle.svg';
import mainIcon from '../Assets/Blog/mail.svg';
import passwordicon from '../Assets/Blog/key.svg';
import { FaGoogle, FaFacebook,FaUser, FaEnvelope, FaLock } from 'react-icons/fa';
import googleIcon from '../Assets/Blog/🦆 icon _google_.svg'
import facebookIcon from '../Assets/Blog/🦆 icon _Facebook v1_.svg'
import './Create.css';
import { Link, json, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import constantRecords from '../../utils/constant';

export default function CreateAccount() {
  const [showPassword, setShowPassword] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [accountCreated, setAccountCreated] = useState(false);
  const [values,setValues] = useState({
    name:'',
    email:'',
    password:'',
    role:''
  })
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate function
  const isAuthenticated = useSelector(state => state.users.isAuthenticated);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (e) => {
    setValues(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }),async () => {
      
      console.log(values); // Log the updated state inside the callback function
    });
  };
  

    const postData = async () => {
      
      try {
        const response = await axios.post(`${constantRecords.domain}/signup`, values, {
          headers: {
            "Content-Type": "application/json"
          }
        });
        dispatch(postUser(response));
      
      } catch (error) {
        console.error('Error while posting data:', error);
      }
    };
  
    
 
  
  const handleSubmit = async (e) => {
    e.preventDefault();
console.log(values)
postData()
    // Validate form data before submitting
    if (!validateForm()) {
      return;
    }

    try {
    //   const url = 'http://localhost:4000/signup'
    //  await fetch(url, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
       
    //     body: values
    //   })
     
    
      // Handle success
      
    } catch (error) {
      // Handle error
      console.error('Error creating account:', error);
    }
  };

  const validateForm = () => {
    // Perform form validation
    // Return true if form is valid, false otherwise
    // You can perform validation on fullName, email, and password here
    // Return false if any validation fails
    return true; // Placeholder return value
  };

  if (accountCreated) {
    navigate('/post'); // Redirect to the desired page after successful account creation
  }

  const handleGoogleSignIn = () => {
    // Implement Google sign-in functionality
  };

  const handleFacebookSignIn = () => {
    // Implement Facebook sign-in functionality
  };
  if (isAuthenticated) {
    navigate('/post');  // Redirect to "/post" if isAuthenticated is true
  }
  return (
    <div className="create-wraper">
      <div className="create-left">
        <img src={createHero} alt="Create Hero" />
      </div>
      <div className="create-right">
        <div className="create-right-wraper">
          <div className="create-nav">
            <img src={createLogo} alt="Create Logo" />
            <button className="create-nav-blog">Blog</button>
          </div>
          <div className="create-h3">
            <h3>Create Account</h3>
          </div>
          <div className="sign-in-options">
            <button className="google-signin" onClick={handleGoogleSignIn}>
              <img src={googleIcon} alt="Google Icon" />
              Sign in with Google
            </button>
            <button className="facebook-signin" onClick={handleFacebookSignIn}>
              <img src={facebookIcon} alt="Facebook Icon" />
              Sign in with Facebook
            </button>
          </div>
          <div className="create-account-form-container">
          <form action="https://example.com/signup" method="post" onSubmit={handleSubmit} className="create-account-form">
              <div className="form-group">
                <img src={userIcon} alt="User Icon" />
                <input type="text" name="name" placeholder="Full Name" onChange={handleChange} />
              </div>
              <div className="form-group">
                <img src={mainIcon} alt="Main Icon" />
                <input type="email" name="email" placeholder="Email Address" required  onChange={handleChange}/>
              </div>
              <div className="form-group">
                <img src={passwordicon} alt="Password Icon" />
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  required
                  onChange={handleChange}
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash color="#9D9D9D" /> : <FaEye color="#9D9D9D"  />}
                </span>
              </div>
              <button type="submit" className="submit-btn">Create Account</button>
            </form>
          </div>
          <div className="create-option">
            <p>Already have an account? <Link to="/signin"><span>Log in</span></Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}
