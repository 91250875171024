import React,{useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import './navbar.css'
import createLogo from './Assets/Blog/Layer 2.svg'
import userPic from './Assets/Blog/Ellipse 1.svg'
import NavProfile from './Assets/Blog/profile.svg'
import navBlog from './Assets/Blog/Blog (1).svg'
import navStat from './Assets/Blog/Stats.svg'
import navSignout from './Assets/Blog/log out.svg'
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import { Link ,useNavigate} from 'react-router-dom';
import { useData } from './Preview/GetData';
import { logoutUser } from './Slices/User.slice';

export default function BlogNav() {
  const {data} = useData()
  console.log(data)
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const profilepic = useSelector(state => state.users.userProfilePic)
    const username = useSelector(state => state.users.currentUser.user.name);
    const navigate= useNavigate()
    const dispatch = useDispatch()
    const toggleProfileMenu = () => {
      setShowProfileMenu(!showProfileMenu);
    };
  return (
    <div className='blog-nav-wrapper'>
     <div className='nav-logo' >
        <img src={createLogo}/>
        <button className='create-nav-blog'>Blog</button>
     </div>
     <div>
     <div className="navbar-profile" >
     <Link to="/Blog"><button>Write  blog </button></Link>
        
     <img src={profilepic?profilepic:userPic} alt="Profile Picture" onClick={()=>navigate('/user')}  style={{  objectFit: 'contain', borderRadius: '50%' }}/>
        <span>{username}</span>
        <span onClick={toggleProfileMenu}>{showProfileMenu ? <SlArrowUp/> : <SlArrowDown/>}</span>
      </div>
      {showProfileMenu && (
        <div className="profile-menu">
          <ul>
            <li onClick={()=>navigate('/user')}>
                <img src={NavProfile} alt='nav profile'/>
                 <p>Profile</p>
                </li>
            <li>

            <img src={navBlog} alt='nav blog'/>
                 <p>Blogs</p>
            </li>
            <li onClick={()=>navigate('/stat')}>
            <img src={navStat} alt='nav statistics'/>
                 <p>Statistics</p>
                </li>
          </ul>
          <button onClick={()=>dispatch(logoutUser(null))}>
            <img src={navSignout} alt='sig out'/>
            <span>Sign out</span>
          </button>
        </div>
      )}
     </div>
    </div>
  )
}
