// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import UserReducer from '../Slices/User.slice';
import PostSlice from '../Slices/Post.slice';

// Configure Redux store
const Store = configureStore({
  reducer: {
    users: UserReducer,
    posts:PostSlice
  },
});

export default Store;
