import React, { useRef, useCallback ,useState, useEffect} from "react";
import { useData } from '../../Preview/GetData';
import { useDispatch, useSelector } from 'react-redux';
// import tools for editor config
import { EDITOR_JS_TOOLS } from "./Tools/Tools";
import './editor.css'
import { setData } from "../../Slices/Post.slice";
// create editor instance
import { createReactEditorJS } from "react-editor-js";

import EditorTextParser from "./Editor-parser/Editor_parser";
export default function Editor({ data}) {
	const { setData } = useData();
	
const [postdata,setPostdata]=useState(null)
	const editorCore = useRef(null);
	const ReactEditorJS = createReactEditorJS();
const dispatch = useDispatch()
	const handleInitialize = useCallback((instance) => {
		// await instance._editorJS.isReady;
		instance._editorJS.isReady
			.then(() => {
				// set reference to editor
				editorCore.current = instance;
			})
			.catch((err) => console.log("An error occured", err));
	}, []);

	const handleSave = useCallback(async () => {
		// retrieve data inserted
		try {
			// retrieve data inserted
			const savedData = await editorCore.current.save();
			// save data
			setPostdata(savedData)
		setData(savedData)
			//console.log(savedData)
			//(setData(savedData))
			//setPostdata(savedData)
		
		} catch (error) {
			console.error('Error dispatching data:', error);
		}
	}, [dispatch]);
	

	return (
		<div className="editor-container" style={{width:"100%"}}>
			<h4 className="edit-mode-alert"></h4>
			<ReactEditorJS
				onInitialize={handleInitialize}
				tools={EDITOR_JS_TOOLS}
				onChange={handleSave}
				defaultValue={data}
				placeholder='start-typing'
			/>
			<div className="review-post">
			{postdata?<EditorTextParser data={postdata}/>:null}
			</div>
			
			
		</div>
	);
}
