import React, { useState } from 'react';
import './user.css';
import axios from 'axios';
import userPic from '../Assets/Blog/Ellipse 1.svg';
import Edit from '../Assets/Blog/Edit.svg';
import Editp from '../Assets/Blog/edit p.svg';
import BlogNav from '../BlogNav';
import { setProfilepic } from '../Slices/User.slice';
import { useDispatch, useSelector } from 'react-redux';

export default function UserProfile() {
    const profilepic = useSelector(state => state.users.userProfilePic)
  
  const [editableFields, setEditableFields] = useState({
    name: '',
    email: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [profilePic, setProfilePic] = useState(userPic); // Initialize with default userPic
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.users.currentUser.user);
console.log(editableFields)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableFields({ ...editableFields, [name]: value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setProfilePic(reader.result);
        uploadFile(file)
      };
    }
  };
  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "y49547ki"); // Replace with your Cloudinary upload preset

    fetch("https://api.cloudinary.com/v1_1/dh7rgdtuw/image/upload", {
      method: "POST",
      body: formData
    })
    .then((response) => response.json())
    .then((data) => {
      // Handle the response from Cloudinary
     
      dispatch(setProfilepic(data.url))
      console.log(data);
    })
    .catch((error) => {
      console.error('Error uploading file:', error);
    });
  };
  console.log(profilepic)
  const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNiMGMxN2QzLThmNjktNDNkMy05M2M1LTE0MjJjMDQ3YTk0NCIsImlhdCI6MTcxMjkwNjI4MywiZXhwIjoxNzEyOTkyNjgzfQ.Bn3ojocQg_04J2mGbCLNqmx_RvQjFvtO7nq2QRVQmsM';
  const handleSaveChanges = async () => {
    if (editableFields.newPassword !== editableFields.confirmPassword) {
      console.error('New password and confirm password do not match');
      // Optionally, you can display an error message to the user
      return;
    }
    try {
      const response = await axios.put(`http://localhost:4000/update/${currentUser.id}`, {name:editableFields.name,email:editableFields.email,password:editableFields.confirmPassword,UserPic:profilepic}, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + token
        }
      });
      console.log('Data updated:', response.data);
      // If needed, dispatch an action to update local state with the changes
    } catch (error) {
      console.error('Error while saving changes:', error);
    }
  };

  return (
    <div>
      <div><BlogNav /></div>
      <div className='user-wrapper'>
        <div className='user-header'>
          <h3 className='user-h3'>
            Account Details
          </h3>
        </div>
        <div class="profile-container">
          <div class="profile-pic">
            <img src={profilepic?profilepic:userPic} alt="Profile Picture" style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '50%' }} />
            <div class="edit-icon-container">
              <div class="edit-icon">
                <input id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                <img src={Edit} alt="Edit Icon" onClick={() => document.getElementById('file').click()} />
              </div>
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className='item-colum'>
            <div className="input-wrapper">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" placeholder={currentUser?currentUser.name:null} value={editableFields.name} onChange={handleInputChange} />
            </div>
            <div className="input-wrapper">
              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" name="email" placeholder={currentUser?currentUser.email:null} value={editableFields.email} onChange={handleInputChange} />
            </div>
            <div className="input-wrapper">
              <label htmlFor="newPassword">New Password</label>
              <input type="password" id="newPassword" name="newPassword" value={editableFields.newPassword} onChange={handleInputChange} />
            </div>
            <div className="input-wrapper">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input type="password" id="confirmPassword" name="confirmPassword" value={editableFields.confirmPassword} onChange={handleInputChange} />
            </div>
          </div>
        </div>
        <button onClick={handleSaveChanges}>Save</button>
      </div>
    </div>
  );
}
