// PostCard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
export default function PostCard({ id, title, description, createdAt, onEdit, onDelete, onRestore, postType }) {
    const navigate = useNavigate()
    const formattedCreatedAt = new Date(createdAt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    const calculateTimeSince = (date) => {
        const now = new Date();
        const diff = now - date;
        const minutes = Math.floor(diff / (1000 * 60)); // Convert milliseconds to minutes
        const hours = Math.floor(minutes / 60); // Convert minutes to hours
        const days = Math.floor(hours / 24); // Convert hours to days
        const years = Math.floor(days / 365); // Convert days to years
    
        if (years > 0) {
            return `${years} year${years !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return `Less than a minute ago`;
        }
    };
    
    const [timeSincePosted, setTimeSincePosted] = useState(calculateTimeSince(new Date(createdAt)));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeSincePosted(calculateTimeSince(new Date(createdAt)));
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, [createdAt]);
    const handleView = (postId) => {
        navigate(`/post/${postId}`);
      };
    return (
        <div className="post-card" onClick={()=>handleView(id)}>
            <div className="post-title">{title}</div>
            <div className="post-description">{description}</div>
            <div className="post-metadata">
               {/* Updated to display formatted creation time */}
                <span className='last-edited'>last edited: {timeSincePosted} </span>
                <div className='card-button'>
                    {!['trash'].includes(postType) && <button className='edit-btn' onClick={() => onEdit(id)}>Edit</button>}
                    {postType === 'draft' && <button className='delete-btn' onClick={() => onDelete(id)}>Delete Draft</button>}
                    {postType === 'published' && <button className='delete-btn' onClick={() => onDelete(id)}>Delete Published</button>}
                    {postType === 'trash' && (
                        <>
                        <button className='delete-btn' onClick={() => onDelete(id)}>Remove from Trash</button>
                            <button className='restore-btn' onClick={()=>onRestore(id)}>Restore</button>
                            
                        </>
                    )}
                </div>
            </div>
            
            <div className='card-line'></div>
        </div>
    );
}
