// src/store/userSlice.js
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import axios from 'axios';
import constantRecords from '../../utils/constant';

const defaultUsers = [
  {
    id: 1,
    fullName: 'sseruwagi williams favor',
    email: 'san12williams@gmail.com',
    password: 'San12@2020',
  },
];
// Define an initial state for the user slice
const initialState = {
  currentUser: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  userProfilePic: null,
  users: [],
};

// Define a thunk to post user data asynchronously
export const postUser = createAsyncThunk (
  'users/postUserFromResponse',
  async response => {
    try {
      const {user, token} = response.data;

      // Set the token in the local storage
      localStorage.setItem ('token', token);

      // Return the user details
      return response.data; // Assuming response contains the user details
    } catch (error) {
      throw new Error ('Failed to create account');
    }
  }
);
export const updateUserEmail = createAsyncThunk (
  'users/updateUserEmail',
  async ({id, email, token}) => {
    try {
      // Replace the endpoint with your actual API endpoint
      const response = await axios.put (
        `${constantRecords.domain}/update/:id${id}`,
        {
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming response contains the updated user data
    } catch (error) {
      throw new Error ('Failed to update user email');
    }
  }
);

// Define a thunk to fetch users asynchronously
export const loginUser = createAsyncThunk ('users/fetchUsers', async () => {
  try {
    const response = await axios.get (`${constantRecords.domain}/login`); // Replace with your actual API endpoint
    return response.data;
  } catch (error) {
    throw new Error ('Failed to fetch users');
  }
});

// Define a thunk to authenticate user
export const authenticateUser = createAsyncThunk (
  'users/authenticateUser',
  async response => {
    try {
      return response.data;
    } catch (error) {
      throw new Error ('Failed to authenticate user');
    }
  }
);

// Create a user slice
const UserSlice = createSlice ({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
      state.isAuthenticated = true;
    },
    logoutUser: state => {
      state.currentUser = null;
      state.isAuthenticated = false;
    },
    setProfilepic: (state, action) => {
      state.userProfilePic = action.payload;
    },
    setAuthenticated: state => {
      state.isAuthenticated = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase (postUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase (postUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.isAuthenticated = true;
      })
      .addCase (postUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase (loginUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase (loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase (loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase (authenticateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.isAuthenticated = true;
      })
      .addCase (authenticateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase (updateUserEmail.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase (updateUserEmail.fulfilled, (state, action) => {
        state.loading = false;
        // Update user email in state
        state.currentUser.email = action.payload.email;
      })
      .addCase (updateUserEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const {
  setUser,
  logoutUser,
  setAuthenticated,
  setProfilepic,
} = UserSlice.actions;
export default UserSlice.reducer;
