import React, { useState ,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import store from '../Store/Store'
import UserSlice, { authenticateUser, setAuthenticated } from '../Slices/User.slice';
import {fetchPosts, getPostsId} from '../Slices/Post.slice'
import googleIcon from '../Assets/Blog/🦆 icon _google_.svg';
import facebookIcon from '../Assets/Blog/🦆 icon _Facebook v1_.svg';
import createHero from '../Assets/Blog/OBJECTS (1).svg';
import createLogo from '../Assets/Blog/Layer 2.svg';
import mainIcon from '../Assets/Blog/mail.svg';
import passwordicon from '../Assets/Blog/key.svg';

import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import constantRecords from '../../utils/constant';
export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [ profile, setProfile ] = useState([]);
  const [ user, setUser ] = useState([]);
  // const id = useSelector(state => state.users.currentUser.user.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.users.isAuthenticated);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${constantRecords.domain}/login`, {email,password}, {
        headers: {
          "Content-Type": "application/json"
        }
      });
     
    
       dispatch(authenticateUser(response));
      
       
    } catch (error) {
      console.error('Authentication error:', error.message);
    }

    if (isAuthenticated) {
      navigate('/post');
    
    }
  };
  const handleGoogleSignIn  = useGoogleLogin({
    onSuccess: () => {
      // Dispatch setUser action with the user data
      dispatch(setAuthenticated(true));
     if (isAuthenticated) {
      navigate('/post');
    }
    },
    onError: (error) => console.log('Login Failed:', error)  
});
console.log(user)
  const handleFacebookSignIn = async () => {
    try {
       dispatch(authenticateUser({ provider: 'facebook' }));
    } catch (error) {
      console.error('Facebook authentication error:', error.message);
    }
  };
//   useEffect(
//     () => {
//         if (user) {
//             axios
//                 .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
//                     headers: {
//                         Authorization: `Bearer ${user.access_token}`,
//                         Accept: 'application/json'
//                     }
//                 })
//                 .then((res) => {
//                     setProfile(res.data);
//                 })
//                 .catch((err) => console.log(err));
//         }
//     },
//     [ user ]
// );
if (isAuthenticated) {

  navigate('/post');  // Redirect to "/post" if isAuthenticated is true
}
  return (
    <div className="create-wraper">
      <div className="create-left signin-left">
        <img src={createHero} alt="Create Hero" />
      </div>
      <div className="create-right">
        <div className="create-right-wraper">
          <div className="create-nav">
            <img src={createLogo} alt="Create Logo" />
            <button className="create-nav-blog">Blog</button>
          </div>
          <div className="create-h3">
            <h3>Sign In</h3>
          </div>
          <div className="sign-in-options">
            <button className="google-signin" onClick={handleGoogleSignIn}>
              <img src={googleIcon} alt="Google Icon" />
              Sign in with Google
            </button>
            <button className="facebook-signin" onClick={handleFacebookSignIn}>
              <img src={facebookIcon} alt="Facebook Icon" />
              Sign in with Facebook
            </button>
          </div>
          <div className="create-divide">
            <h3>- OR -</h3>
          </div>
          <div className="create-account-form-container">
            <form className="create-account-form">
              <div className="form-group">
                <img src={mainIcon} alt="Main Icon" />
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Email Address"
                  required
                />
              </div>
              <div className="form-group">
                <img src={passwordicon} alt="Password Icon" />
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                  required
                />
                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash color="#9D9D9D" /> : <FaEye color="#9D9D9D" />}
                </span>
              </div>
              <span className="forgot-password">I forgot my password</span>
              <button type="submit" className="submit-btn" onClick={handleSubmit}>
                Sign in
              </button>
            </form>
          </div>
          <div className="create-option">
            <p>
              No Account? <Link to="/create"><span>Create One!</span></Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
