import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SignIn from './Components/SignIn/SignIn';
import { setAuthenticated ,setUser } from './Components/Slices/User.slice';
import Blog from './Components/blogPost/Indexblog';
import Post from './Components/Posts/Post';
import Stat from './Components/Statistics/Stat';
import UserProfile from './Components/UserProfile/UserProfile';
import Preview from './Components/Preview/Preview';
import Blogpost from './Components/Posts/Blogpost';
import CreateAccount from './Components/CreateAcount/CreateAccount';
import { DataProvider } from './Components/Preview/GetData';

function App() {
  const isAuthenticated = true;
  const token = true;
  //console.log(token)

  return (
    <GoogleOAuthProvider clientId="1056600415170-9cn2lo9gm3hftp21eono93i9sij75eg3.apps.googleusercontent.com">
    <BrowserRouter>
    <DataProvider>
    <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/create" element={<CreateAccount />} />
        {/* Protected Routes */}
        <Route path="/blog" element={isAuthenticated &&token ? <Blog /> : <Navigate to="/signin" />} />
        <Route path="/post" element={isAuthenticated &&token ? <Post /> : <Navigate to="/signin" />} />
        <Route path="/stat" element={isAuthenticated &&token ? <Stat /> : <Navigate to="/signin" />} />
        <Route path="/user" element={isAuthenticated &&token ? <UserProfile /> : <Navigate to="/signin" />} />
        <Route path="/preview" element={isAuthenticated &&token ? <Preview /> : <Navigate to="/signin" />} />
        <Route path="/post/:id" element={<Blogpost />} />
        {/* Add a catch-all route for unknown routes */}
        <Route path="/*" element={<Navigate to="/signin" />} />
      </Routes>
    </DataProvider>
     
    </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
