// src/store/postSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import constantRecords from '../../utils/constant';

// Define an initial state for the post slice
const initialState = {
  singlepost:null,// Initial content of the post
  htmlContent: '', // Transformed HTML content of the post
  loading: false,
  error: null,
  title: '',
  coverPhotoUrl: null,
  AllowComments: false,
  description: null,
  categories: null,
  isApproved: false,
  metaTitle:null,
  metaDescription:null,
  user_posts: [],
  PublisedPosts: [],
  TrashPosts: [],
  DraftPosts: [],
};

// Define a thunk to save post content asynchronously
export const savePostContent = createAsyncThunk('post/savePostContent', async (response) => {
  try {
    // Replace the endpoint with your actual API endpoint

    return response.data; // Assuming response contains any necessary data after saving
  } catch (error) {
    throw new Error('Failed to save post content');
  }
});
export const fetchPublised = createAsyncThunk('post/fetchPublised', async (id) => {
  try {

    // Replace the endpoint with your actual API endpoint
    const response = await axios.get(`${constantRecords.domain}/${id}`);
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to fetch posts');
  }
});
export const fetchDraft = createAsyncThunk('post/fetchDraft', async (id) => {
  try {

    
    // Replace the endpoint with your actual API endpoint
    const response = await axios.get(`${constantRecords.domain}/draft/${id}`);
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to fetch posts');
  }
});

// Define a thunk to fetch posts from the database asynchronously

export const fetchPosts = createAsyncThunk('post/fetchPosts', async (id) => {
  try {

    // Replace the endpoint with your actual API endpoint
    const response = await axios.get(`${constantRecords.domain}/posts/${id}`);
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to fetch posts');
  }
});
export const fetchPostById = createAsyncThunk('post/fetchPostsbyid', async (id) => {
  try {

    // Replace the endpoint with your actual API endpoint
    const response = await axios.get(`http://localhost:4000/postid/${id}`);
    console.log(response)
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to fetch posts');
  }
});


// Create a post slice
const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setHtmlContent: (state, action) => {
      state.htmlContent = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setDescription: (state, action) => {
      state.description = action.payload
    },
    getPostsId: (state, action) => {
      state.user_posts = action.payload
    },
    setCoverPhotoUrl: (state, action) => {
      state.coverPhotoUrl = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    setmetaTitle:(state,action)=>{
state.metaTitle= action.payload
    },
    setmetaDescription:(state,action)=>{
state.metaDescription = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(savePostContent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePostContent.fulfilled, (state) => {
        state.loading = false;
        // You can handle any necessary state changes after successfully saving the post
      })
      .addCase(savePostContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.user_posts = action.payload
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPublised.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPublised.fulfilled, (state, action) => {
        state.loading = false;
        state.PublisedPosts = action.payload
      })
      .addCase(fetchPublised.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDraft.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDraft.fulfilled, (state, action) => {
        state.loading = false;
        state.DraftPosts = action.payload
      })
      .addCase(fetchDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPostById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPostById.fulfilled, (state, action) => {
        state.loading = false;
        state.singlepost = action.payload
      })
      .addCase(fetchPostById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { setData, setHtmlContent, setTitle, setDescription, getPostsId, setCoverPhotoUrl, setCategories ,setmetaDescription,setmetaTitle} = postSlice.actions;
export default postSlice.reducer;
