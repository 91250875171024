import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import '../blogPost/index.css'
import '../navbar.css'
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { SlArrowUp } from "react-icons/sl";
import tooltipMenue from '../Assets/Blog/image 12.svg'
import Add from '../Assets/Blog/Add.svg'
import Editor from "./Editor/Editor";
import EditorTextParser from "./Editor/Editor-parser/Editor_parser";
// import exampleData from "./Editor/data/Data";
import { setData, setTitle, setCoverPhotoUrl, setCategories } from '../Slices/Post.slice';
//nav
import { useData } from '../Preview/GetData';
import createLogo from '../Assets/Blog/Layer 2.svg'
import userPic from '../Assets/Blog/Ellipse 1.svg'
import NavProfile from '../Assets/Blog/profile.svg'
import navBlog from '../Assets/Blog/Blog (1).svg'
import navStat from '../Assets/Blog/Stats.svg'
import navSignout from '../Assets/Blog/log out.svg'
import { SlArrowDown } from "react-icons/sl";
import Preview from '../Preview/Preview';
import { current } from '@reduxjs/toolkit';

const NavBlog = () => {
    const profilepic = useSelector(state => state.users.currentUser.user.UserPic)
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const navigate = useNavigate()
    const toggleProfileMenu = () => {
        setShowProfileMenu(!showProfileMenu);
    };
    console.log(profilepic)
    const username = useSelector(state => state.users.currentUser.user.name);
    return (
        <div className='blog-nav-wrapper'>
            <div className='nav-logo' >
                <img src={createLogo} />
                <button className='create-nav-blog'>Blog</button>
            </div>
            <div>
                <div className="navbar-profile" >


                    <img src={profilepic ? profilepic : userPic} alt="Profile Picture" onClick={() => navigate('/user')} />
                    <span>{username}</span>
                    <span onClick={toggleProfileMenu}>{showProfileMenu ? <SlArrowUp /> : <SlArrowDown />}</span>
                </div>
                {showProfileMenu && (
                    <div className="profile-menu">
                        <ul>
                            <li>
                                <img src={NavProfile} alt='nav profile' />
                                <p>Profile</p>
                            </li>
                            <li>

                                <img src={navBlog} alt='nav blog' />
                                <p>Blogs</p>
                            </li>
                            <li>
                                <img src={navStat} alt='nav statistics' />
                                <p>Statistics</p>
                            </li>
                        </ul>
                        <button>
                            <img src={navSignout} alt='sig out' />
                            <span>Sign out</span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
export default function Index() {
    const { data,setData } = useData()
    console.log(data)
    const [openPreview, setPreview] = useState(false)
    const [openCategory, setCategory] = useState(false);
    const [openPublish, setPublish] = useState(false)
    const [openComment, setComment] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false);
    const [title, settitle] = useState('')
    const [value, onChange] = useState(new Date());
    const [openSchedule, setOpenSchudule] = useState(false)
    const username = useSelector(state => 'mund');
    const coverImg = useSelector(state => state.posts.coverPhotoUrl)
    const exampleData = useSelector(state => state.posts.data)
    const dispatch = useDispatch()
    const [uploadedPhoto, setUploadedPhoto] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the first file selected by the user

        //console.log(file, 'file record');

        

        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {

                uploadFile(file) // Update the state with the base64 encoded image URL
            };
        }

    };
   // console.log(openPreview)
    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "y49547ki"); // Replace with your Cloudinary upload preset

        fetch("https://api.cloudinary.com/v1_1/dh7rgdtuw/image/upload", {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from Cloudinary
                setUploadedPhoto(data.url)
                dispatch(setCoverPhotoUrl(data.url))
                console.log(data);
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
            });
    };
    const handtitleChange = (e) => {
        settitle(e.target.value)
        dispatch(setTitle(title))
    }

    const categoriesList = [
        {
            id: 1,
            name: "business"
        }
        ,
        {
            id: 2,
            name: "Cat"
        },
        {
            id: 3,
            name: "Categ 1"
        }, {
            id: 4,
            name: "Categores]]]]]]] 1"
        }
    ]
    const ScheduleList = [
        {
            name: "Automatic"
        },
        {
            name: "Set date and time"
        }
    ]
    const Checkbox = ({ isChecked, label, checkHandler, index }) => {
        return (
            <div className='categories-ul-li'>
                <input
                    type="checkbox"
                    id={`checkbox-${index}`}
                    checked={isChecked}
                    onChange={checkHandler}
                />
                <label htmlFor={`checkbox-${index}`}>{label}</label>
            </div>
        )
    }
    const [checkedState, setCheckedState] = useState(categoriesList);
    console.log(checkedState)
    const handleOnChecked = (index) => {
        setCheckedState(
            checkedState.map((category, currentIndex) =>
                currentIndex === index
                    ? { ...category, checked: !category.checked }
                    : category
            )
        )
    };
    const selectedCategoryNames = checkedState
        .filter(category => category.checked)
        .map(category => category.name);
    dispatch(setCategories(selectedCategoryNames))
    console.log(selectedCategoryNames);
    const [selectedOption, setSelectedOption] = useState('automatic');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [commentOption, setCommentOption] = useState('automatic');

    const handleCommentChange = (event) => {
        setCommentOption(event.target.value);
    };
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleClick = event => {
        setCategory(current => !current);
    };
    const handleClickSchudule = event => {
        setOpenSchudule(current => !current);
    };
    function toggleEditMode() {
        if (isEditMode) {
            setIsEditMode(false);
            console.log("Edit mode is now disabled");
        } else {
            setIsEditMode(true);
            console.log("Edit mode is now enabled");
        }
    }
    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };
    const fileInputRef = React.createRef(); // Create a ref for the file input

    return (

        <div className='post-wrapper1'>
           
            {!openPreview&&(
                  <div>
                     <div className='nav-wrapper'><NavBlog /></div>
            <div className='post-publish-wrapper'>

                <div className='post-publish'>
                    <div className='tooltipblog'></div>
                    <button className='publish-btn' onClick={(event) => setPreview(current => !current)}>publish</button>


                </div>
            </div>

            <div className='post-edit'>
                <div className='post-text-edit'>
                    <div className='post-cover-photo'>
                        {!uploadedPhoto && !coverImg ? ( // Conditionally render based on uploadedPhoto state
                            <>
                                <h2 className='cover-photo-h2'>Add cover photo here</h2>
                                <h3 className='cover-photo-h3'>We recommend uploading or dragging in an image that is 1920x1080 pixels</h3>

                                <div className='post-edit-cover-upload'>
                                    <input id="file" type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={fileInputRef} />
                                    <button className='upload-cover-p' onClick={handleUploadButtonClick}>Upload from your computer</button>
                                </div>
                            </>
                        ) : (
                            <img src={coverImg ? coverImg : uploadedPhoto} alt='Cover' className='uploaded-cover-photo' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        )}
                    </div>

                    <div className='post-edit-content'>

                        <div className='bog-title'>
                            <textarea name="text" rows="5" cols="10" wrap="soft" placeholder='ADD TITLE' onChange={handtitleChange}></textarea>
                        </div>

                        <div className='start-typing'>



                            <div className="app-content" style={{ width: "100%" }}>

                                <Editor data={data ? data : null} setData={setData} />




                            </div>
                        </div>
                    </div>
                </div>
                <div className='post-side-bar'>
                    <div className='post-settings'><h3 className='post-settings-h3'>Post settings</h3></div>
                    <div className='side-settings'>

                        <div className='post-categories'>
                            <div className='post-settings-wrapper'>
                                <p className='post-setting-label'>categories</p>
                                <div onClick={handleClick} className='post-settings-arrow'><SlArrowUp /></div>

                            </div>
                            <div className='divider'></div>
                            {openCategory && (
                                <ul className='categories-ul'>
                                    {checkedState.map((category, index) => (
                                        <Checkbox
                                            key={category.id}
                                            isChecked={category.checked}
                                            checkHandler={() => handleOnChecked(index)}
                                            label={category.name}
                                            index={index}
                                        />
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div>
                            <div className='post-settings-wrapper'>
                                <p className='post-setting-label'>Schedule Publish Date</p>
                                <div ><SlArrowUp onClick={(event) => setPublish(current => !current)} /></div>

                            </div>
                            <div className='divider'></div>
                            {openPublish && (
                                <div className="post-radio">

                                    <label>    <input
                                        id='label-post'
                                        type="radio"
                                        value="automatic"
                                        checked={selectedOption === 'automatic'}
                                        onChange={handleOptionChange}
                                    />
                                        Automatic
                                    </label>
                                    <label>
                                        <input
                                            id='label-post'
                                            type="radio"
                                            value="schedule"
                                            checked={selectedOption === 'schedule'}
                                            onChange={handleOptionChange}

                                        />
                                        Schedule
                                    </label>
                                </div>
                            )}


                            {selectedOption === 'schedule' && openPublish && (
                                <div>
                                    <div className='display-shedule'> <div className='selected-date-time'>
                                        <p>{selectedDate.toDateString()}</p>

                                    </div>
                                        <div className='selected-time'>
                                            <p>10.46 AM</p>

                                        </div></div>
                                    <Calendar onChange={handleDateChange} value={value} />
                                </div>
                            )}
                            <div>
                                <div className='post-settings-wrapper'>
                                    <p className='post-setting-label'>Comments</p>
                                    <div ><SlArrowUp onClick={(event) => setComment(current => !current)} /></div>

                                </div>
                                <div className='divider'></div>
                                {openComment && (
                                    <div className="post-radio">
                                        <label>
                                            <input
                                                id='label-post'
                                                type="radio"
                                                value="Allow comments"
                                                checked={commentOption === 'Allow comments'}
                                                onChange={handleCommentChange}
                                            />
                                            Allow comments
                                        </label>
                                        <label>
                                            <input
                                                id='label-post'
                                                type="radio"
                                                value="Dont allow comments"
                                                checked={commentOption === 'Dont allow comments'}
                                                onChange={handleCommentChange}

                                            />
                                            Don't allow comments
                                        </label>
                                    </div>
                                )}


                            </div>

                        </div>

                        <div> <div className='post-settings-wrapper'>
                            <p className='post-setting-label'>Author Name</p>
                            <div ><SlArrowUp /></div>

                        </div>
                            <div className='divider'></div>
                            <div className='author-name'><p>{username}</p></div>
                        </div>
                    </div>

                </div>
            </div>
                  </div>  
                    )}
            <div>
               
                {openPreview && (
                    <Preview rawdata={data} setPreview={setPreview} setData={setData}/>
                )}
            </div>
        </div>
    )
}
