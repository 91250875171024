import React, {useState, useEffect} from 'react';
import {json, useNavigate} from 'react-router-dom';
import './preview.css';
import {
  savePostContent,
  setDescription,
  setTitle,
  setCoverPhotoUrl,
} from '../Slices/Post.slice';
import {useDispatch, useSelector} from 'react-redux';
import EditorTextParser from '../blogPost/Editor/Editor-parser/Editor_parser';
import {BiX} from 'react-icons/bi';
import CoverIcon from '../Assets/Blog/Image.svg';
import axios from 'axios';
import {DataProvider} from './GetData';
import {useData} from '../Preview/GetData';
import {useEditorData} from '../blogPost/Editor/Editor';
import {setmetaDescription, setmetaTitle} from '../Slices/Post.slice';
import {current} from '@reduxjs/toolkit';
import constantRecords from '../../utils/constant';

export default function Preview({rawdata, setPreview, setData}) {
  const [description1, setdescription] = useState ('');
  const username = useSelector (state => state.users.currentUser.user.name);
  const data = JSON.stringify ({rawdata});

  const title = useSelector (state => state.posts.title);
  const description = useSelector (state => state.posts.description);
  const id = useSelector (state => 1);
  const coverImg = useSelector (state => state.posts.coverPhotoUrl);
  const dispatch = useDispatch ();
  const token = useSelector (state => state.users.currentUser.token);

  

  const navigate = useNavigate ();
  const categories = useSelector (state => state.posts.categories);
  const stingCategories = JSON.stringify ({categories});
  console.log (stingCategories);

  const handleDraft = async e => {
    e.preventDefault ();
    try {
      const response = await axios.post (
        `${constantRecords.domain}/posts/${id}`,
        {
          title,
          data,
          description,
          coverImgUrl: coverImg,
          categories: stingCategories,
          status: 'Draft',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch (savePostContent (response));

      console.log (id);
    } catch (error) {
      console.log (error);
    }
    if (Response) {
      navigate ('/post');
    }
  };
  const handleClick = async e => {
    e.preventDefault ();
    try {
      const response = await axios.post (
        `${constantRecords.domain}/posts/${id}`,
        {
          title,
          data,
          description,
          coverImgUrl: coverImg,
          categories: stingCategories,
          status: 'pending',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch (savePostContent (response));

      console.log (id);
      // Clear state data after successful post
      dispatch (setCoverPhotoUrl ());
      setData ();

      // Navigate only when the post has been successfully posted
      navigate ('/post');
    } catch (error) {
      console.log (error);
    }
  };
  const handledescription = e => {
    setdescription (e.target.value);
    dispatch (setDescription (description1));
  };

  return (
    <div className="preview-wrapper">
      <div className="preview-close">
        <div
          className="preview-close-body"
          onClick={() => setPreview (current => !current)}
        >
          <span className=""> <BiX /></span>
          <p className="close-preview-p">Close preview</p>
        </div>
      </div>
      <div className="preview-header">
        <h3 className="preview-h3">Story Preview</h3>
        <p className="preview-header-p">
          Include a high-quality image in your story to make it more inviting to readers. This will act as a cover image for your story
        </p>
      </div>
      <div className="section-preview">
        <div className="preview-left">
          <div className="preview-cover">
            {coverImg
              ? <img
                  src={coverImg}
                  alt="Cover"
                  className="uploaded-cover-photo"
                  style={{width: '100%', height: '100%', objectFit: 'cover'}}
                />
              : <img src={CoverIcon} />}

          </div>
          <div class="input-container-preview">
            <input
              type="text"
              placeholder="Write a preview title"
              onChange={e => setTitle (e.target.value)}
              value={title ? title : null}
            />
          </div>

          <div class="input-container-preview">
            <input
              type="text"
              placeholder="Write a preview description"
              onChange={handledescription}
            />
          </div>

        </div>
        <div className="preview-right">
          <div className="preview-right-header">
            <p className="preview-right-p">
              Publishing as:
              {' '}
              <span className="preview-right-p-span">
                {username ? username : null}
              </span>
            </p>
          </div>
          <div className="input-container-preview-right-parent">
            <div class="input-container-preview-right">
              <label for="Meta Title">Meta Title</label>
              <input
                type="text"
                id="Meta Title"
                onChange={e => dispatch (setmetaTitle (e.target.value))}
              />
            </div>

            <div class="input-container-preview-right">
              <label for="Meta Description">Meta Description</label>
              <input
                type="text"
                id="Meta Description"
                onChange={e => dispatch (setDescription (e.target.value))}
              />
            </div>
          </div>
          <div className="preview-publish-now">
            <button onClick={handleClick}>Publish Now</button>
            <p
              className="preview-schedule
                    "
              onClick={handleDraft}
            >
              Schedule for later
            </p>
          </div>
        </div>

      </div>
      <div className="preview-content-post">
        <div className="post-preview-post-header">
          <h3>
            This is how your post will be displayed to the reader
          </h3>
        </div>
        <div className="text-div">
          <h3>{title ? title : null}</h3>
          {rawdata ? <EditorTextParser data={rawdata} /> : null}
        </div>

      </div>
    </div>
  );
}
